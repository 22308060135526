<template>
	<div class="font ajust-margin" style="margin-top: 80px; margin-right: 95px; margin-left: 95px;">
		<div style="background: #FFFFFF; box-shadow: 0px 10px 10px rgba(226, 236, 249, 0.5);; border-radius: 30px;">
			<div style="padding-top: 28px; margin-left: 38px;  margin-right: 38px; margin-bottom:10px" class="d-flex justify-content-between">
				<p class="title">Novo Cliente</p>
				<div class="div-botoes">
					<button class="light-button dark-text" style="width: 150px; background: #F9FBFF ; border: 1px solid #E7E7E7; border-radius: 8px 0px 0px 8px">
						DADOS DA EMPRESA
					</button>

					<button class="dark-button light-text" style="width: 150px; background: #6DA1B5; border-radius: 0px 8px 8px 0px">
						REPRESENTANTES
					</button>
				</div>
			</div>
		<RepresentanteForm
			@added-representative="
				(clientId) => {
					this.$router.push('/PreContratos/novo/' + clientId);
				}
			"
			@cancel="
				() => {
					this.$router.push('/clientes');
				}
			"
		></RepresentanteForm>
	</div>
	</div>
</template>

<style scoped lang="sass">
@import "~/public/styles/sass/index.scss"
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
  -webkit-appearance: none
  margin: 0
</style>

<script>
// import { required, numeric, alphaNum, alpha } from "vuelidate/lib/validators";
import ClientService from "../../services/ClientService/ClientService";

import RepresentanteForm from "../../components/Cliente/RepresentanteForm.vue";

export default {
	components: {
		RepresentanteForm,
	},
	data() {
		return {
			clientService: new ClientService(),
		};
	},
};
</script>
