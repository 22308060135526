<template>
	<div>
		<form id="form-cliente" @submit.prevent="createRepresentative()" style="padding: 38px;">
			<div class="row gx-5">
				<div class="form-group col-md-3">
					<label for="regiao" style="font-size: 12px"
						>Tipo de Representante</label
					>
					<b-select
						style="
							background: #f5f4fc 0% 0% no-repeat padding-box;
							border-radius: 2px;
							opacity: 1;
						"
						class="form-control"
						required
						v-model="representative.representative_type"
						:options="[
							// {
							// 	value: 0,
							// 	text: 'Representante Legal',
							// },
							{ value: 1, text: 'Representante Técnico' },
							{ value: 2, text: 'Representante Financeiro' },
						]"
					></b-select>
				</div>

				<div class="form-group col-md-3">
					<label for="cnpj" style="font-size: 12px"
						>Nome e Sobrenome</label
					>
					<input
						style="
							background: #f5f4fc 0% 0% no-repeat padding-box;
							border-radius: 2px;
							opacity: 1;
						"
						type="text"
						class="form-control"
						id="cnpj"
						v-model="representative.full_name"
						required
					/>
				</div>
				<div class="form-group col-md-3">
					<label for="inputRazaoSocial" style="font-size: 12px"
						>CPF</label
					>
					<input
						style="
							background: #f5f4fc 0% 0% no-repeat padding-box;
							border-radius: 2px;
							opacity: 1;
							text-transform: uppercase;
						"
						v-mask="'###.###.###-##'"
						type="text"
						class="form-control"
						id="inputRazaoSocial"
						v-model="representative.cpf"
					/>
				</div>

				<div class="form-group col-md-3">
					<label for="fantasia" style="font-size: 12px"
						>Telefone</label
					>
					<input
						v-mask="'(##) #####-####'"
						v-model="representative.phone"
						style="
							background: #f5f4fc 0% 0% no-repeat padding-box;
							border-radius: 2px;
							opacity: 1;
							text-transform: uppercase;
						"
						type="text"
						class="form-control"
						id="fantasia"
					/>
				</div>
			</div>

			<div class="row gx-5">
				<div class="form-group col-md-3">
					<label for="inputCep" style="font-size: 12px">Função</label>
					<input
						style="
							background: #f5f4fc 0% 0% no-repeat padding-box;
							border-radius: 2px;
							opacity: 1;
						"
						type="text"
						class="form-control"
						id="inputCep"
						v-model="representative.occupation"
						required
					/>
				</div>

				<div class="form-group col-md-6">
					<label for="inputCep" style="font-size: 12px">E-mail</label>
					<input
						style="
							background: #f5f4fc 0% 0% no-repeat padding-box;
							border-radius: 2px;
							opacity: 1;
							text-transform: uppercase;
						"
						type="text"
						class="form-control"
						id="inputaddress"
						v-model="representative.email"
						required
					/>
				</div>

				<div class="form-group col-md-3">
					<label for="inputCep" style="font-size: 12px"
						>Data de Aniversário</label
					>
					<date-picker
						style="display: unset"
						required
						v-model="representative.birthday_date"
						format="DD/MM/YYYY"
						valueType="YYYY-MM-DD"
						input-class="form-control crm-input date-picker"
					></date-picker>
				</div>
			</div>
			<div class="row gx-5">
				<div class="form-group col-md-12">
					<label for="inputCep" style="font-size: 12px"
						>Endereço Residencial</label
					>
					<input
						style="
							background: #f5f4fc 0% 0% no-repeat padding-box;
							border-radius: 2px;
							opacity: 1;
							text-transform: uppercase;
							height: 60px;
						"
						type="text"
						class="form-control"
						id="inputCidade"
						v-model="representative.address"
						required
					/>
				</div>
			</div>
			<div
				style="
					display: flex;
					flex-direction: row-reverse;
					margin-bottom: 10px;
					margin-top: 30px;
				"
			>
				<button
					type="submit"
					class="btn btn-primary py-2 px-4"
					style="
						background: #006992;
						border-radius: 8px;
						padding-left: 30px;
						padding-right: 30px;
						border: none
					"
				>
					CONTINUAR
				</button>

				<router-link to="/adicionarCliente">
					<button
						type="reset"
						class="btn btn-secondary py-2 px-4"
						style="
							background: #7E7E7E;
							border-radius: 8px;
							padding-left: 30px;
							padding-right: 30px;
							margin-right: 20px;
							border: none;
						"
						@click="
							() => {
								this.$emit('cancel');
							}
						"
					>
						VOLTAR
					</button>
				</router-link>
			</div>
		</form>
	</div>
</template>

<script>
import Swal from "sweetalert2";

import RepresentativeService from "../../services/RepresentativeService/representativeService";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/pt-br";

export default {
	components: {
		DatePicker,
	},
	props: ["value"],
	mounted() {
		if (this.value) {
			this.$nextTick(() => {
				console.log(JSON.parse(JSON.stringify(this.value)));
				this.requested_client = true;
				this.client = this.value;
			});
		}
	},
	data() {
		return {
			representative_service: new RepresentativeService(),

			representative: {
				full_name: "",
				cpf: "",
				phone: "",
				occupation: "",
				email: "",
				birthday_date: "",
				address: "",
				representative_type: 1,
			},
			// size_limits: {
			// 	cnpj: 14,
			// 	nome: 60,
			// 	fantasia: 60,
			// 	cep: 8,
			// 	end: 60,
			// 	comp: 20,
			// 	estado: 2,
			// 	cidade: 30,
			// 	bairro: 30,
			// 	contato: 20,
			// 	email: 200,
			// 	fone: 11,
			// },
			// requested_client: false,
		};
	},

	methods: {
		createRepresentative() {
			this.submmited = true;
			let representative = Object.assign({}, this.representative);
			let query = this.$route.query;
			var phone = representative.phone.replace(/\D/g, "");
			representative.phone = phone;

			console.log(query);

			let promise = new Promise((resolve) => {
				resolve();
			});

			if (Object.hasOwnProperty.call(query, "clientId")) {
				representative.cliente = query.clientId;
				promise = this.representative_service
					.create(representative)
					.catch(() => {
						Swal.fire({
							title: "Falha!",
							text: "Não foi possivel cadastrar o representante, verifique o formulário",
							icon: "error",
							showCancelButton: false,
							confirmButtonColor: "#0bb4d4",
							cancelButtonColor: "#A9A9A9",
							confirmButtonText: "OK",
							cancelButtonText: "Cancelar",
						});
					})
					.then(() => {
						Swal.fire({
							title: "Atualizado!",
							text: "Representante criado com sucesso",
							icon: "success",
							showCancelButton: false,
							confirmButtonColor: "#0bb4d4",
							cancelButtonColor: "#A9A9A9",
							confirmButtonText: "OK",
							cancelButtonText: "Cancelar",
						});
					});
			}
			promise.then(() => {
				this.$emit("added-representative", representative.cliente);
			});
			console.log("Form submitted", representative);
			parent.document.getElementById("form-cliente").reset();
		},
	},
	computed: {
		formIsValid() {
			return this.representative.full_name && this.representative.cpf;
		},
	},
};
</script>
