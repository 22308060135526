import baseService from "../baseService";

/*
Product Service
*/
const PRODUCTS_ENDPOINT = "/api/representatives/";
export default class RepresentativeService extends baseService {
	constructor() {
		super(PRODUCTS_ENDPOINT, "representatives");
	}
}
